<template>
  <div>


    <infoFormMessage
        v-if="typeof result.infoFormMessage != 'undefined'"
        :message="result.infoFormMessage"
        :result="result"
    ></infoFormMessage>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-card class="" outlined width="700">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{
                language.pageTitle_orgGeneralSettings
              }}
            </v-toolbar-title>
          </v-toolbar>
          <v-spacer></v-spacer>
          <v-form
              ref="orgGeneralSettings"
              action="serve.php/?f=administration&f2=organisationGeneralSettings"
              method="post"
              v-model="isValid"
              @submit.prevent
          >
            <v-list-item>
              <v-list-item-content>
                <div class="body-2">
                  <v-row>
                    <v-col>
                      <fieldsSingleRows
                          @field-value-changed="checkInput(...arguments)"
                          field="n_id"
                          :templateContent="result"
                          :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                          @field-value-changed="checkInput(...arguments)"
                          field="n_id_key"
                          :templateContent="result"
                          :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                          @field-value-changed="checkInput(...arguments)"
                          field="c_name"
                          :templateContent="result"
                          :valueLine="result.valueLine"
                          class="mb-n5"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                          @field-value-changed="checkInput(...arguments)"
                          field="useExplorerAlias"
                          :templateContent="result"
                          :valueLine="result.valueLine"
                          class="mb-n5 ml-1 mt-3"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                          @field-value-changed="checkInput(...arguments)"
                          field="disableDemoProject"
                          :templateContent="result"
                          :valueLine="result.valueLine"
                          class="mb-5 ml-2"
                      ></fieldsSingleRows>
                      <div class="subtitle-2 ml-3">
                        {{ language.componentLabel.passwordSecurity }}
                      </div>
                      <!-- Idea is on hold -->
                      <!--<fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        class="ml-2 mb-n4"
                        field="checkRules"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows>-->
                      <fieldsSingleRows
                          @field-value-changed="checkInput(...arguments)"
                          class="ml-2 mb-n4"
                          field="pWantedPassCode"
                          :templateContent="result"
                          :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                          @field-value-changed="checkInput(...arguments)"
                          class="ml-2 "
                          field="enable2FA"
                          :templateContent="result"
                          :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <OrganizationLocationPicker
                          :valueLine="locationValue"
                          @location="checkInput(...arguments)"/>
                      <input type="hidden" :value="JSON.stringify(locationValue)" name="c_obj"/>
                      -                     <!-- <input type="hidden" value="{}" name="c_obj"/>-->
                    </v-col>
                  </v-row>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions class="max-auto">
              <fieldsButtons
                  :result="result"
                  @submitted="submitForm(...arguments)"
                  :isValid="isValid"
              ></fieldsButtons>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
    <div class="pt-10"></div>
  </div>
</template>

<script>
import fieldsSingleRows from "@/commonComponents/fieldsSingleRows.vue";
import fieldsButtons from "@/commonComponents/fieldsButtons.vue";
import infoFormMessage from "@/commonComponents/infoFormMessage.vue";
import OrganizationLocationPicker from '@/components/specific/OrganizationLocationPicker';

export default {
  components: {
    OrganizationLocationPicker,
    infoFormMessage,
    fieldsSingleRows,
    fieldsButtons,
  },
  props: ["result"],
  data: function () {
    return {
      isValid: false,
      requiredPage: window.location,
      panel: [0],
    };
  },
  methods: {
    submitForm(functionName) {
      this.ajaxSubmit(
          functionName,
          this.$refs.orgGeneralSettings.$el,
          this.result.tableUrl,
          this.result
      );

      this.$notify({
        group: "foo",
        text: "Successfully Updated!!",
        duration: 1000,
        type: "success",
        speed: 600,
      });
    },
    checkInput(field, value) {
      if (typeof this.result.valueLine == "undefined") {
        this.$set(this.result, "valueLine", {});
      }
      this.$set(this.result.valueLine, field, value);
      this.$emit("dialog-result-changed", this.result);
    },
  },
  computed: {
    locationValue() {
      let value;
      try {
        value = JSON.parse(this.result.valueLine.c_obj);
      } catch (error) {
        value = this.result.valueLine.c_obj;
      }
      return value;
    },
    radios: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  }
};
</script>